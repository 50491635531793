import React from "reactn"
import { graphql } from "gatsby"
import { Layout } from "../components/layoutRoot"
import { SectionListing } from "../components/section-listing"
import { Seo } from "../components/seo"
import { PageContent } from "../components/pageContent"
import Datalayer from "../components/dataLayer"
export default function SinglePage({ location, data: { singlePage } }) {
  const {
    name,
    seo
  } = singlePage

  const {
    title
  } = seo
  const ogType = name == "index" ? "website" : "article"
  return (
    <>
      <Datalayer location={location} />
      <Seo seoMetaTags={singlePage.seoMetaTags} og_type={ogType} location={location} />
      <PageContent h1Tag={title}>
        <SectionListing sections={singlePage.sections} location={location} iStory={false}/>
      </PageContent>
    </>
  )
}
SinglePage.Layout = Layout

export const query = graphql`
  query($id: String!) {
    singlePage: datoCmsSinglepage(id: { eq: $id }) {
      name
      seo {
        title
      }
      internal{
        type
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      sections {
        ... on DatoCmsSection {
          ...SectionCard
        }
      }
    }    
  }
`
